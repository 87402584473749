import BurgerMenu from "./BurgerMenu";
import * as React from "react";
import Home from "./Home";
import EditFilmList from "./EditFilmList";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { FilmList } from "../modules/films/types";
import { Colors } from "../helpers/colors";
import { Provider } from "react-redux";
import { store } from "../utils/middleware";
import { setInitialData } from "../utils/hooks";

type Props = {
  user_data: { first_name: string };
  film_lists: FilmList[];
};

const App = ({ user_data, film_lists }: Props) => {
  return (
    <Provider store={store}>
      <Flicklist user_data={user_data} film_lists={film_lists} />
    </Provider>
  );
};

const Flicklist = ({ user_data, film_lists }: Props) => {
  const userFirstName = user_data?.first_name;

  setInitialData(film_lists);

  return (
    <BrowserRouter>
      <BurgerMenu />
      <div style={styles.everythingWrapper}>
        <Routes>
          <Route path="/" element={<Home userFirstName={userFirstName} />} />
          <Route path="/film_lists/:id/edit" element={<EditFilmList />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
};

const styles = {
  userHeader: (isMobile: boolean) =>
    ({
      fontFamily: '"Noto Sans", sans-serif',
      textTransform: "lowercase",
      fontWeight: 400,
      color: Colors.kindaBlack,
      fontSize: isMobile ? 38 : 58,
    } as React.CSSProperties),
  userHeaderWrapper: (isMobile: boolean) => ({
    display: "flex",
    marginLeft: 28,
    marginRight: 28,
    justifyContent: isMobile ? "center" : "flex-start",
    marginTop: isMobile ? 48 : 0,
  }),
  everythingWrapper: {
    padding: 8,
  },
};

export default App;
