import { FilmList, Film } from "../modules/films/types";
import { apiClient } from "./client";

export const createWatchedMarker = async (
  film_list_id: FilmList["id"],
  film_id: Film["id"]
) => {
  try {
    return await apiClient.post(`/api/v1/watched_markers/`, {
      film_list_id,
      film_id,
    });
  } catch (err) {
    throw err;
  }
};

export const deleteWatchedMarker = async (
  film_list_id: FilmList["id"],
  film_id: Film["id"]
) => {
  try {
    return await apiClient.delete(
      `/api/v1/watched_markers/1?film_list_id=${film_list_id}&film_id=${film_id}`
    );
  } catch (err) {
    throw err;
  }
};
