import { Film } from "../modules/films/types";
import { apiClient } from "./client";

const filmUrls = {
  createFilm: "/api/v1/films",
  searchFilms: (query: string) => `/api/v1/films/search?query=${query}`,
};

export const createFilmRequest = async (filmParams: Film) => {
  const { title, description, image_url } = filmParams;
  r;
  try {
    return await apiClient.post(filmUrls.createFilm, {
      title,
      description,
      image_url,
    });
  } catch (err) {
    throw err;
  }
};

export const searchFilmsRequest = async (query: string) => {
  try {
    return await apiClient.get(filmUrls.searchFilms(query));
  } catch (err) {
    throw err;
  }
};
