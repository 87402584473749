import { define } from 'remount'
import App from './components/App'
import Home from './components/Home'
import Signup from './components/Signup'
import Signin from './components/Signin'

define({
  'app-component': App,
  'home-component': Home,
  'signup-component': Signup,
  'signin-component': Signin,
})