import React from "react";
import { logoutRequest } from "../api/sessions";
import { slide as Menu } from "react-burger-menu";
import { Home as HomeIcon } from "@mui/icons-material";
import Logout from "@mui/icons-material/Logout";
import Add from "@mui/icons-material/Add";
import { Colors } from "../helpers/colors";
import { createFilmList } from "../api/filmLists";
import { useNavigate } from "react-router-dom";
import { generateRandomName } from "../helpers/randomNameGenerator";

const BurgerMenu = () => {
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await logoutRequest();
      window.location.replace("/");
    } catch (err) {
      console.log("hey err");
    }
  };

  const handleCreateList = async () => {
    try {
      const response = await createFilmList(generateRandomName());
      const filmList = response.data.film_list;
      navigate(`/film_lists/${filmList.id}/edit`, { state: filmList });
    } catch (err) {
      console.log("hey error creating film list");
    }
  };

  return (
    <Menu right>
      <MenuItem label={"home"} icon={<HomeIcon />} href={"/"} />
      <MenuItem label={"new list"} icon={<Add />} onClick={handleCreateList} />
      <div style={styles.dividerStyle}></div>
      <MenuItem label={"logout"} icon={<Logout />} onClick={handleLogout} />
    </Menu>
  );
};

const styles = {
  dividerStyle: {
    backgroundColor: Colors.kindaBlack,
    height: 1,
    opacity: 0.2,
  },
};

type MenuItemProps = {
  label: string;
  icon: any;
  href?: string;
  onClick?: () => void;
};

const MenuItem = ({ label, icon, href, onClick }: MenuItemProps) => (
  <a
    id={label}
    style={{ display: "flex", alignItems: "flex-start" }}
    className="bm-item menu-item"
    href={href}
    onClick={onClick}
  >
    <div style={{ marginRight: 8 }}>{icon}</div>
    {label}
  </a>
);

export default BurgerMenu;
