import * as React from "react";
import { FilmList } from "../modules/films/types";
import PageHeader from "./PageHeader";
import ScrollableFilmList from "./ScrollableFilmList";
import FilmListHeader from "./FilmListHeader";
import { useSelector } from "react-redux";
import { selectFilmLists } from "../modules/filmLists/selectors";

type Props = {
  userFirstName: string;
};

const Home = ({ userFirstName }: Props) => {
  const filmLists = useSelector(selectFilmLists);

  return (
    <>
      <PageHeader label={`${userFirstName}'s flicklists`} />
      {filmLists.length > 0 ? (
        filmLists.map((list: FilmList, index: number) => {
          return (
            <div key={index}>
              <FilmListHeader filmList={list} />
              <ScrollableFilmList filmListId={list.id} films={list.films} />
            </div>
          );
        })
      ) : (
        <ScrollableFilmList films={[]} noFilmLists />
      )}
    </>
  );
};

export default Home;
