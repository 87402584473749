import * as React from "react";
import { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { FilmList, Film } from "../modules/films/types";
import PageHeader from "./PageHeader";
import FilmSelect from "./FilmSelect";
import ScrollableFilmList from "./ScrollableFilmList";
import { useIsMobile } from "../hooks/useIsMobile";
import { useSelector } from "react-redux";
import { selectFilmLists } from "../modules/filmLists/selectors";
import { intersection, uniq } from "lodash";
import { Box, Chip, Typography } from "@mui/material";
import { Colors } from "../helpers/colors";

const EditFilmList = () => {
  const { id } = useParams();
  const filmLists = useSelector(selectFilmLists);
  const listFromState = useLocation().state as FilmList;
  const isMobile = useIsMobile();
  const filmList =
    listFromState || filmLists.find((list) => list.id === Number(id));
  const [justSavedFilms, setJustSavedFilms] = useState<Film[]>([]);
  const [allFilms, setAllFilms] = useState<Film[]>([]);
  const [filteredFilms, setFilteredFilms] = useState<Film[]>([]);

  const [genreFilters, setGenreFilters] = useState<string[]>([]);

  const uniqueGenres = uniq(
    filteredFilms.map((film) => film.genres).flat()
  ).sort();

  useEffect(() => {
    setFilteredFilms(filmList?.films);
  }, [filmList]);

  useEffect(() => {
    setAllFilms([...justSavedFilms, ...(filmList?.films || [])]);
  }, [filmList, justSavedFilms]);

  useEffect(() => {
    setFilteredFilms(
      allFilms.filter(
        (film) =>
          intersection(genreFilters, film.genres).length === genreFilters.length
      )
    );
  }, [genreFilters, allFilms]);

  if (!filmList) {
    return <></>;
  }

  const handleGenreFilterClick = (genre: string) => {
    const genreIndex = genreFilters.indexOf(genre);
    const genreFilteredAlready = genreIndex > -1;

    if (genreFilteredAlready) {
      setGenreFilters(
        genreFilters.filter((genreFilter) => genre !== genreFilter)
      );
    } else {
      setGenreFilters([...genreFilters, genre]);
    }
  };

  return (
    <div style={styles.wrapper(isMobile)}>
      <PageHeader
        filmListId={id!}
        label={filmList.name}
        noLeftMargin
        editFilmListPage
      />
      <FilmSelect
        filmListId={id || ""}
        onSuccess={(film: Film) => setJustSavedFilms([film, ...justSavedFilms])}
      />
      <Box sx={{ mb: 1 }}>
        <Typography>Tap genres to filter films</Typography>
        {uniqueGenres.map((genre) => (
          <Chip
            key={`genre-filter-chip-${genre}`}
            label={genre}
            onClick={() => handleGenreFilterClick(genre)}
            style={
              genreFilters.includes(genre)
                ? { ...styles.genreFilterChip, ...styles.selectedGenreFilter }
                : styles.genreFilterChip
            }
          />
        ))}
      </Box>
      <ScrollableFilmList
        filmListId={parseInt(id || "")}
        films={filteredFilms}
        leftAlignedWithTwoRows
      />
    </div>
  );
};

const styles = {
  wrapper: (isMobile: boolean) => ({
    paddingLeft: isMobile ? 0 : 38,
  }),
  image: {
    height: 200,
  },
  optionImage: {
    height: 150,
  },
  genreFilterChip: {
    marginRight: 4,
    marginBottom: 4,
  },
  selectedGenreFilter: {
    backgroundColor: Colors.orange,
  },
};

export default EditFilmList;
