import { default as axios } from "axios";

type Headers = {
  "X-CSRF-Token": string;
};

const buildHeaders = (): Headers => {
  const csrfToken = document
    .getElementsByName("csrf-token")[0]
    .getAttribute("content");
  return { "X-CSRF-Token": csrfToken || "" };
};

export const apiClient = axios.create({
  baseURL: "",
  withCredentials: false,
  headers: buildHeaders(),
});
