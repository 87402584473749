import * as actions from "./actions";
import reducer, { initialState } from "./reducer";
import { default as sagas } from "./sagas";

export default {
  actions,
  reducer,
  initialState,
  sagas,
};
