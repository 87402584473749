import { Film } from "../modules/films/types";

type FilmRows = {
  rowOne: Film[];
  rowTwo: Film[];
  rowThree?: Film[];
};

export const splitFilmsIntoRows = (
  isMobile: boolean,
  films: Film[]
): FilmRows => {
  const result: FilmRows = isMobile
    ? { rowOne: [], rowTwo: [], rowThree: [] }
    : { rowOne: [], rowTwo: [] };

  if (films.length === 0) return result;

  const splitBy = isMobile ? 3 : 2;

  for (let i = 0; i < films.length; i += splitBy) {
    const [rowOne, rowTwo, rowThree] = films.slice(i, i + splitBy);

    if (!!rowOne) {
      result.rowOne.push(rowOne);
    }
    if (!!rowTwo) {
      result.rowTwo.push(rowTwo);
    }
    if (isMobile && !!rowThree) {
      result.rowThree!.push(rowThree);
    }
  }

  return result;
};
