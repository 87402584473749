import { all, call, put, takeEvery } from "redux-saga/effects";
import * as actions from "./actions";
import * as apiClient from "../../api/filmLists";
import { ActionType, getType } from "typesafe-actions";
import { AxiosResponse } from "axios";

function* handleRemoveFilmFromListRequest({
  payload,
}: ActionType<typeof actions.removeFilmFromList.request>) {
  const response: AxiosResponse = yield call(
    apiClient.removeFilmFromFilmListRequest,
    payload
  );

  if (response.status === 200) {
    yield put(actions.removeFilmFromList.success(payload));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(
      getType(actions.removeFilmFromList.request),
      handleRemoveFilmFromListRequest
    ),
  ]);
}
