import * as React from "react";
import { Typography } from "@mui/material";
import { useState } from "react";
import { createUserRequest } from "../api/users";
import { Colors } from "../helpers/colors";

const Signup = () => {
  const [email, setEmail] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const handleSubmit = async (event: React.SyntheticEvent) => {
    try {
      event.preventDefault();
      await createUserRequest({ email, password, firstName });
      window.location.replace("/");
    } catch (err) {
      console.log(err);
      console.log("HEY AN ERRO");
    }
  };

  return (
    <div style={styles.wrapper}>
      <form style={styles.form} onSubmit={handleSubmit}>
        <Typography variant="h2">flicklist.lol</Typography>
        <input
          style={styles.textInput}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          placeholder="email"
          type="email"
        />
        <input
          style={styles.textInput}
          onChange={(e) => {
            setFirstName(e.target.value);
          }}
          placeholder="first name"
        />
        <input
          style={styles.textInput}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          placeholder="password"
          type="password"
        />
        <input style={styles.submitButton} type="submit" value="signup" />
      </form>
    </div>
  );
};

const styles = {
  wrapper: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: 300,
  } as React.CSSProperties,
  textInput: {
    height: 40,
    width: "100%",
    padding: 10,
    borderRadius: 8,
    fontSize: 20,
    marginBottom: 10,
    backgroundColor: Colors.eggShell,
    borderWidth: "thin",
    borderColor: Colors.darkGreen,
    webkitAppearance: "none",
  },
  submitButton: {
    width: 320,
    height: 50,
    borderRadius: 8,
    fontSize: 20,
    backgroundColor: Colors.orange,
    borderWidth: 0,
    color: Colors.eggShell,
    marginBottom: 10,
  },
};

export default Signup;
