import { CreateUserParams } from "../modules/sessions/types";
import { apiClient } from "./client";

export const createUserRequest = async (params: CreateUserParams) => {
  const { email, firstName, password } = params;

  try {
    return await apiClient.post("/api/v1/users", {
      email,
      first_name: firstName,
      password,
    });
  } catch (err) {
    throw err;
  }
};
