import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";
import { spawn } from "redux-saga/effects";
import { StateType } from "typesafe-actions";
import filmLists from "../modules/filmLists";

const sagaMiddleware = createSagaMiddleware();
const middleware = applyMiddleware(sagaMiddleware);

const reducers = combineReducers({
  filmLists: filmLists.reducer,
});

export type FlicklistRootState = StateType<typeof reducers>;

export const store = createStore(reducers, composeWithDevTools(middleware));

export type WriterToolingDispatch = typeof store.dispatch;

function* rootSaga() {
  yield spawn(filmLists.sagas);
}

sagaMiddleware.run(rootSaga);
