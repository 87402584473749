import { AxiosResponse } from "axios";
import { Film, FilmList } from "../modules/films/types";
import { apiClient } from "./client";

type AddFilmParams = {
  title: Film["title"];
  description: Film["description"];
  image_url: Film["image_url"];
  themoviedb_ref: Film["themoviedbRef"];
  media_type: Film["media_type"];
  genre_ids: number[];
};

export const createFilmList = async (name: FilmList["name"]) => {
  try {
    return await apiClient.post(`/api/v1/film_lists/`, { name });
  } catch (err) {
    throw err;
  }
};

export const addFilmToFilmListRequest = async (
  filmListId: string,
  filmParams: AddFilmParams
) => {
  try {
    return await apiClient.patch(
      `/api/v1/film_lists/${filmListId}/add_film_to_list`,
      filmParams
    );
  } catch (err) {
    throw err;
  }
};

export type RemoveFilmFromListParams = {
  filmListId: FilmList["id"];
  filmId: Film["id"];
};

export const removeFilmFromFilmListRequest = async (
  params: RemoveFilmFromListParams
): Promise<AxiosResponse> => {
  try {
    const { filmListId, filmId } = params;
    return await apiClient.patch(
      `/api/v1/film_lists/${filmListId}/remove_film_from_list`,
      { film_id: filmId }
    );
  } catch (err) {
    throw err;
  }
};

export const updateFilmList = async (id: string, name: FilmList["name"]) => {
  try {
    return await apiClient.patch(`/api/v1/film_lists/${id}`, { name });
  } catch (err) {
    throw err;
  }
};
