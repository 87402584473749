import { createStandardAction, createAsyncAction } from "typesafe-actions";
import { RemoveFilmFromListParams } from "../../api/filmLists";
import { Film, FilmList } from "../films/types";

export const setFilmLists =
  createStandardAction("SET_FILM_LISTS")<FilmList[]>();

export const putWatchedFilmsAtEnd = createStandardAction(
  "PUT_WATCHED_FILMS_AT_END"
)();

export const removeFilmFromList = createAsyncAction(
  "REMOVE_FILM_FROM_LIST_REQUEST",
  "REMOVE_FILM_FROM_LIST_SUCCESS",
  "REMOVE_FILM_FROM_LIST_FAILED"
)<RemoveFilmFromListParams, RemoveFilmFromListParams, undefined>();
