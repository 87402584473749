import { Edit } from "@mui/icons-material";
import { Typography, IconButton } from "@mui/material";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Colors } from "../helpers/colors";
import { useIsMobile } from "../hooks/useIsMobile";
import { FilmList } from "../modules/films/types";

type Props = {
  filmList: FilmList;
};

const FilmListHeader = ({ filmList }: Props) => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const navigateToEditFilm = (filmList: FilmList) => {
    navigate(`/film_lists/${filmList.id}/edit`);
  };
  return (
    <div style={styles.titleWrapper}>
      <div style={styles.titleWithIcon}>
        <Typography
          sx={Object.assign(
            {},
            styles.listName,
            isMobile && styles.listNameMobile
          )}
          variant="h5"
        >
          {filmList.name}
        </Typography>
        {
          // @ts-ignore
          <IconButton
            style={styles.editIcon}
            onClick={() => navigateToEditFilm(filmList)}
          >
            <Edit />
          </IconButton>
        }
      </div>
    </div>
  );
};

const styles = {
  titleWrapper: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 12,
  },
  editIcon: {
    color: Colors.orange,
    width: 50,
    position: "absolute",
    top: 0,
    bottom: 0,
    left: "100%",
  } as React.CSSProperties,
  titleWithIcon: {
    position: "relative",
  } as React.CSSProperties,
  listName: {
    textTransform: "lowercase",
    fontFamily: '"Noto Sans", sans-serif',
    fontWeight: 300,
    color: Colors.kindaBlack,
    fontSize: 39,
  } as React.CSSProperties,
  listNameMobile: {
    fontSize: 28,
  } as React.CSSProperties,
};

export default FilmListHeader;
