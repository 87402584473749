import { apiClient } from "./client";

export const loginRequest = async (params: UserParams) => {
  const { email, password } = params;

  try {
    return await apiClient.post("/api/v1/sessions", { email, password });
  } catch (err) {
    throw err;
  }
};

export const logoutRequest = async () => {
  try {
    return await apiClient.delete("/api/v1/sessions/1");
  } catch (err) {
    throw err;
  }
};
