import * as React from "react";
import { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import Checkbox from "@mui/material/Checkbox";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  Chip,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  Typography,
} from "@mui/material";
import { Film, FilmList } from "../modules/films/types";
import { Colors } from "../helpers/colors";
import {
  createWatchedMarker,
  deleteWatchedMarker,
} from "../api/watchedMarkers";
import { useDispatch } from "react-redux";
import {
  putWatchedFilmsAtEnd,
  removeFilmFromList,
} from "../modules/filmLists/actions";

type Props = {
  film: Film;
  filmListId: FilmList["id"];
  onClose: () => void;
  open: boolean;
  onWatchedPress: (watched: boolean, filmId: Film["id"]) => void;
};

export const FilmDialog = ({
  film,
  filmListId,
  onClose,
  open,
  onWatchedPress,
}: Props) => {
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);

  useEffect(() => {
    setChecked(!!film.watched);
  }, [film]);

  const handleClose = () => {
    onClose();
  };

  const handleDeleteConfirmationClose = async (confirmDelete: boolean) => {
    if (confirmDelete) {
      handleRemoveFilmFromList();
    } else {
      setDeleteConfirmationOpen(false);
    }
  };

  const handleChange = async () => {
    if (checked) {
      const response = await deleteWatchedMarker(filmListId, film.id);
      if (response.status === 200) {
        const watched = false;
        setChecked(watched);
        onWatchedPress(watched, film.id);
      }
    } else {
      const response = await createWatchedMarker(filmListId, film.id);
      if (response.status === 200) {
        const watched = true;
        setChecked(watched);
        onWatchedPress(watched, film.id);
      }
    }

    dispatch(putWatchedFilmsAtEnd());
  };

  const handleRemoveFilmFromList = async () => {
    dispatch(removeFilmFromList.request({ filmListId, filmId: film.id }));
    setDeleteConfirmationOpen(false);
    onClose();
  };

  return (
    <Dialog maxWidth={"xs"} onClose={handleClose} open={open}>
      <div style={styles.everythingWrapper}>
        <div style={styles.imageWrapper}>
          <div style={styles.filmTitleWrapper}>
            <div style={styles.titleAndCheckbox}>
              <Typography sx={styles.filmTitle} variant="caption">
                {film.title}
              </Typography>
              <FormControl style={{ alignSelf: "center" }} component="fieldset">
                <FormGroup aria-label="position" row>
                  <FormControlLabel
                    value="top"
                    sx={{ mr: 0 }}
                    control={
                      <Checkbox
                        sx={{
                          color: Colors.eggShell,
                          "&.Mui-checked": {
                            color: Colors.eggShell,
                          },
                        }}
                        checked={checked}
                        onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label="Watched"
                    labelPlacement="top"
                  />
                </FormGroup>
              </FormControl>
            </div>
          </div>
          <IconButton
            style={styles.deleteIcon}
            onClick={() => setDeleteConfirmationOpen(true)}
          >
            <DeleteIcon style={{ fontSize: 20 }} />
          </IconButton>
          <img style={styles.image} src={film.image_url} alt={film.title} />
        </div>
        <div style={styles.chipsBackground}>
          {film.genres.map((genre) => (
            <Chip
              key={`genre-chip-${genre}`}
              label={genre}
              variant="outlined"
              size="small"
              style={styles.genreChip}
            />
          ))}
        </div>
        <Typography sx={styles.description} variant="body2">
          {film.description}
        </Typography>
      </div>
      <DeleteConfirmationDialog
        open={deleteConfirmationOpen}
        onClose={handleDeleteConfirmationClose}
      />
    </Dialog>
  );
};

type DeleteConfirmationDialogProps = {
  open: boolean;
  onClose: (confirmDelete: boolean) => void;
};

const DeleteConfirmationDialog = ({
  open,
  onClose,
}: DeleteConfirmationDialogProps) => {
  return (
    <Dialog
      open={open}
      onClose={() => onClose(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent style={{ backgroundColor: Colors.eggShell }}>
        <DialogContentText
          color={Colors.kindaBlack}
          id="alert-dialog-description"
        >
          Remove this film from the list?
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ backgroundColor: Colors.eggShell }}>
        <Button
          style={{ color: Colors.kindaBlack }}
          onClick={() => onClose(false)}
        >
          No
        </Button>
        <Button
          style={{ backgroundColor: Colors.orange, color: Colors.kindaBlack }}
          onClick={() => onClose(true)}
          autoFocus
        >
          Yes, I'm sure
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const styles = {
  image: {
    width: "inherit",
    height: "inherit",
    objectFit: "cover",
    position: "absolute",
  } as React.CSSProperties,
  imageWrapper: {
    position: "relative",
    width: "inherit",
    height: 326,
  } as React.CSSProperties,
  filmTitle: {
    fontSize: "1.4rem",
    alignSelf: "center",
    width: "70%",
  },
  filmTitleWrapper: {
    position: "absolute",
    bottom: 0,
    backgroundImage:
      "linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.5), rgba(0,0,0,1))",
    color: Colors.eggShell,
    zIndex: "1",
    width: "inherit",
    boxSizing: "border-box",
    padding: "12px 16px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    minHeight: 200,
  } as React.CSSProperties,
  titleAndCheckbox: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  description: {
    backgroundColor: "black",
    color: Colors.eggShell,
    padding: "7px 12px",
    fontSize: "0.9rem",
  },
  deleteIcon: {
    position: "absolute",
    zIndex: 1,
    top: 0,
    right: 0,
    marginTop: 8,
    marginRight: 8,
    color: Colors.kindaBlack,
    backgroundColor: Colors.orange,
    borderRadius: "10%",
  } as React.CSSProperties,
  everythingWrapper: {
    width: 311,
  },
  genreChip: {
    color: "black",
    backgroundColor: Colors.eggShell,
    border: "none",
    marginRight: 4,
  },
  chipsBackground: {
    backgroundColor: "black",
    padding: 7,
  },
};
