import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setFilmLists } from "../modules/filmLists/actions";
import { FilmList } from "../modules/films/types";

export const setInitialData = (filmLists: FilmList[]) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setFilmLists(filmLists));
  }, []);
};
