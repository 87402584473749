import * as actions from "./actions";
import { ActionType, getType } from "typesafe-actions";
import { FilmList } from "../films/types";

export type FilmListsState = {
  filmLists: FilmList[];
};

export const initialState = {
  filmLists: [],
};

const putWatchedFilmsAtEnd = (list: FilmList) => {
  const { films } = list;
  films.sort((x, y) => Number(x.watched) - Number(y.watched));
  return { ...list, ...{ films } };
};

const filmListsReducer = (
  state: FilmListsState = initialState,
  action: ActionType<typeof actions>
) => {
  switch (action.type) {
    case getType(actions.setFilmLists):
      const watchedFilmsAtEnd = [...action.payload].map(putWatchedFilmsAtEnd);
      return {
        ...state,
        filmLists: watchedFilmsAtEnd,
      };
    case getType(actions.removeFilmFromList.success):
      const filmLists = state.filmLists;
      const filmListToUpdate = filmLists.find(
        (list) => list.id === action.payload.filmListId
      );

      if (filmListToUpdate) {
        const indexToReplace = filmLists.indexOf(filmListToUpdate);
        const updatedFilms = filmListToUpdate?.films.filter(
          (film) => film.id !== action.payload.filmId
        );

        filmListToUpdate.films = updatedFilms;
        filmLists[indexToReplace] = filmListToUpdate;

        return {
          ...state,
          filmLists: [...filmLists],
        };
      } else {
        return state;
      }
    case getType(actions.putWatchedFilmsAtEnd):
      return {
        ...state,
        filmLists: [...state.filmLists.map(putWatchedFilmsAtEnd)],
      };
    default:
      return state;
  }
};

export default filmListsReducer;
