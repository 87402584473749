import * as React from "react";
import { useIsMobile } from "../hooks/useIsMobile";
import { Colors } from "../helpers/colors";
import { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from "@mui/material";
import { updateFilmList } from "../api/filmLists";

export enum FontSize {
  Big,
  Bigger,
}

type Props = {
  filmListId?: string;
  label: string;
  noLeftMargin?: boolean;
  fontSize?: FontSize;
  editFilmListPage?: boolean;
};
const PageHeader = ({
  filmListId,
  label,
  noLeftMargin,
  fontSize,
  editFilmListPage,
}: Props) => {
  const isMobile = useIsMobile();
  const [savedLabel, setSavedLabel] = useState<string>(label);
  const [stagedTitle, setStagedTitle] = useState<string>(savedLabel);
  const [editTitleDialogOpen, setEditTitleDialogOpen] =
    useState<boolean>(false);

  const handleSave = async () => {
    if (filmListId) {
      try {
        await updateFilmList(filmListId, stagedTitle);
        setSavedLabel(stagedTitle);
        setEditTitleDialogOpen(false);
      } catch (err) {
        console.log(err, "aaah sheeit");
      }
    }
  };

  const handleCancel = () => {
    setEditTitleDialogOpen(false);
    setStagedTitle(savedLabel);
  };

  return (
    <div>
      <div style={styles.listNameHeaderWrapper(isMobile, !!noLeftMargin)}>
        <div
          style={styles.container}
          onClick={() => !!editFilmListPage && setEditTitleDialogOpen(true)}
        >
          <h1 style={styles.listNameHeader(isMobile, fontSize || FontSize.Big)}>
            {savedLabel}
          </h1>
          {!!editFilmListPage && <h4 style={styles.editText}>tap to edit</h4>}
        </div>
      </div>
      <Dialog open={editTitleDialogOpen} onClose={handleCancel}>
        <DialogContent style={styles.editDialog}>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            value={stagedTitle}
            onChange={(e) => setStagedTitle(e.target.value)}
            fullWidth
            variant="outlined"
          />
        </DialogContent>
        <DialogActions style={styles.editDialog}>
          <Button style={styles.cancelButton} onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            style={styles.saveButton}
            onClick={handleSave}
            variant="contained"
          >
            save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const getFontSize = (isMobile: boolean, fontSize?: FontSize) => {
  if (fontSize === FontSize.Big) {
    return isMobile ? 40 : 84;
  } else if (fontSize === FontSize.Bigger) {
    return isMobile ? 40 : 78;
  }
};

const styles = {
  listNameHeader: (isMobile: boolean, fontSize: FontSize) =>
    ({
      textTransform: "lowercase",
      fontWeight: 400,
      color: Colors.kindaBlack,
      fontSize: getFontSize(isMobile, fontSize),
      marginTop: 10,
      marginBottom: 0,
    } as React.CSSProperties),
  listNameHeaderWrapper: (isMobile: boolean, noLeftMargin: boolean) => ({
    fontFamily: '"Noto Sans", sans-serif',
    display: "flex",
    marginLeft: noLeftMargin ? 0 : 28,
    marginRight: 28,
    justifyContent: isMobile ? "center" : "flex-start",
    marginTop: isMobile ? 48 : 0,
    marginBottom: 24,
  }),
  container: {
    display: "flex",
    flexDirection: "column",
  } as React.CSSProperties,
  editText: {
    textAlign: "center",
    marginTop: 0,
    color: Colors.darkGreen,
  } as React.CSSProperties,
  editDialog: {
    backgroundColor: Colors.eggShell,
  },
  cancelButton: {
    color: Colors.darkGreen,
    textTransform: "lowercase",
  } as React.CSSProperties,
  saveButton: {
    background: Colors.darkGreen,
    textTransform: "lowercase",
  } as React.CSSProperties,
};

export default PageHeader;
