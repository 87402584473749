import * as React from "react";
import Select, { components, DropdownIndicatorProps } from "react-select";
import { useEffect, useRef, useState } from "react";
import { searchFilmsRequest } from "../api/films";
import { addFilmToFilmListRequest } from "../api/filmLists";
import { Film, MediaType } from "../modules/films/types";
import Search from "@mui/icons-material/Search";
import { Colors } from "../helpers/colors";
import { useIsMobile } from "../hooks/useIsMobile";

type TheMovieDbFilm = TheMovieDbFilmMovie | TheMovieDbFilmTv;

type TheMovieDbFilmMovie = {
  id: number;
  media_type: MediaType.MOVIE;
  title: string;
  overview: string;
  poster_path: string;
  backdrop_path: string;
  genre_ids: number[];
};

type TheMovieDbFilmTv = {
  id: number;
  media_type: MediaType.TV;
  name: string;
  overview: string;
  poster_path: string;
  backdrop_path: string;
  genre_ids: number[];
};

type SearchResultOption = {
  id: number;
  label: string;
  value: string;
  description: string;
  imageUrl: string;
  backdropUrl: string;
  mediaType: MediaType;
  genreIds: number[];
};

type Props = {
  filmListId: string;
  onSuccess: (film: Film) => void;
};

const FilmSelect = ({ filmListId, onSuccess }: Props) => {
  const isMobile = useIsMobile();
  const [searchResultOptions, setSearchResultOptions] = useState<
    SearchResultOption[]
  >([]);
  const [isSearchLoading, setIsSearchLoading] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState<SearchResultOption | null>(
    null
  );
  const timeout: { current: NodeJS.Timeout | undefined } = useRef(undefined);

  useEffect(() => {
    if (!!selectedValue) {
      handleSave(selectedValue);
    }
  }, [selectedValue]);

  const handleSelectChange = (id?: number) => {
    const selected = searchResultOptions.find((option) => option.id === id);
    if (selected) {
      setSelectedValue(selected);
    }
  };

  const handleSave = async (selectedSearchResultOption: SearchResultOption) => {
    if (filmListId) {
      try {
        const filmParams = {
          title: selectedSearchResultOption.value,
          description: selectedSearchResultOption.description,
          image_url: selectedSearchResultOption.imageUrl,
          backdrop_url: selectedSearchResultOption.backdropUrl,
          themoviedb_ref: selectedSearchResultOption.id,
          media_type: selectedSearchResultOption.mediaType,
          genre_ids: selectedSearchResultOption.genreIds,
        };
        const response = await addFilmToFilmListRequest(filmListId, filmParams);
        const { value, description, imageUrl, mediaType } =
          selectedSearchResultOption;

        const { id, genres } = response.data;
        const justSaved = {
          id,
          title: value,
          description,
          image_url: imageUrl,
          watched: false,
          themoviedbRef: id,
          media_type: mediaType,
          genres,
        };
        onSuccess(justSaved);
        setSelectedValue(null);
      } catch (err) {
        console.log("WTF", err);
      }
    }
  };

  const transformFilmsToOptions = (
    films: TheMovieDbFilm[]
  ): SearchResultOption[] => {
    const baseImageUrl = "https://image.tmdb.org/t/p/w500";
    return films.map((film) => {
      const title =
        film.media_type === MediaType.MOVIE ? film.title : film.name;
      return {
        id: film.id,
        label: title,
        value: title,
        description: film.overview,
        imageUrl: baseImageUrl + film.poster_path,
        backdropUrl: baseImageUrl + film.backdrop_path,
        mediaType: film.media_type,
        genreIds: film.genre_ids,
      };
    });
  };

  const dummyFilms = [
    {
      label: "Brokeback Mountain",
      value: "Brokeback Mountain",
      description:
        "Two modern-day cowboys meet on a shepherding job in the summer of '63, the two share a raw and powerful summer together that turns into a lifelong relationship conflict",
      imageUrl:
        "https://image.tmdb.org/t/p/w500/fsbzfe9eLOEl5rJX04nPEr1eoAi.jpg",
    },
    {
      label: "The Rocky Horror Picture Show",
      value: "The Rocky Horror Picture Show",
      description:
        "Sweethearts Brad and Janet, stuck with a flat tire during a storm, discover the eerie mansion of Dr. Frank-N-Furter, a transvestite scientist. As their innocence is lost, Brad and Janet meet a houseful of wild characters, including a rocking biker and a creepy butler. Through elaborate dances and rock songs, Frank-N-Furter unveils his latest creation: a muscular man named 'Rocky'.      ",
      imageUrl:
        "https://image.tmdb.org/t/p/w500/3pyE6ZqDbuJi7zrNzzQzcKTWdmN.jpg",
    },
    {
      label: "Hotel Transylvania: Transformania",
      value: "Hotel Transylvania: Transformania",
      description:
        "When Van Helsing's mysterious invention, the \"Monsterfication Ray,\" goes haywire, Drac and his monster pals are all transformed into humans, and Johnny becomes a monster. In their new mismatched bodies, Drac and Johnny must team up and race across the globe to find a cure before it's too late, and before they drive each other crazy.",
      imageUrl:
        "https://image.tmdb.org/t/p/w500/teCy1egGQa0y8ULJvlrDHQKnxBL.jpg",
    },
  ];

  const updateFilmSelectOptions = (input: string) => {
    // uncomment the following line to avoid api requests
    // return setSearchResultOptions(dummyFilms)

    setIsSearchLoading(true);

    // Clear the previous timeout.
    clearTimeout(timeout.current);

    if (input === "") {
      setIsSearchLoading(false);

      return;
    }

    timeout.current = setTimeout(async () => {
      const response = await searchFilmsRequest(input);
      const transformedFilms = transformFilmsToOptions(response.data.results);
      setIsSearchLoading(false);
      setSearchResultOptions(transformedFilms);
    }, 600);
  };

  return (
    <div style={styles.control(isMobile)}>
      <Select
        options={searchResultOptions}
        onChange={(opt) => handleSelectChange(opt?.id)}
        onInputChange={updateFilmSelectOptions}
        value={selectedValue}
        isLoading={isSearchLoading}
        placeholder={"add a flick"}
        styles={customSelectStyles}
        components={{ DropdownIndicator }}
        formatOptionLabel={(film) => (
          <div>
            <img
              style={styles.optionImage}
              src={film.imageUrl}
              alt="film-image"
            />
            <span>{film.label}</span>
          </div>
        )}
      />
    </div>
  );
};

const DropdownIndicator = (
  props: DropdownIndicatorProps<SearchResultOption, true>
) => {
  return (
    <components.DropdownIndicator {...props}>
      <Search style={{ color: Colors.peach }} />
    </components.DropdownIndicator>
  );
};

const styles = {
  optionImage: {
    height: 150,
  },
  control: (isMobile: boolean) => ({
    width: isMobile ? "100%" : "30%",
  }),
  menuList: {
    width: "30%",
  },
};

const customSelectStyles = {
  control: (base: any, state: any) => ({
    ...base,
    padding: 10,
    background: Colors.eggShell,
    borderRadius: 8,
    marginBottom: 28,
  }),
  placeholder: (base: any) => ({
    ...base,
    color: Colors.peach,
    fontFamily: '"Noto Sans", sans-serif',
    fontSize: 22,
  }),
  menu: (base: any) => ({
    ...base,
    borderRadius: 0,
    marginTop: 0,
  }),
  menuList: (base: any) => ({
    ...base,
    padding: 0,
  }),
  input: (base: any) => ({
    ...base,
    color: Colors.orange,
    fontFamily: '"Noto Sans", sans-serif',
  }),
  indicatorSeparator: (base: any) => ({
    ...base,
    display: "none",
  }),
};

export default FilmSelect;
