const availableNames = [
  "Bumfuzzle",
  "Fartlek",
  "Everywhen",
  "Erf",
  "Dingus",
  "Meldrop",
  "Obelus",
  "Sozzled",
  "Dongle",
  "Titter",
  "Smicker",
  "Cleek",
  "Noob",
  "Schmooze",
  "Smaze",
  "Skirl",
  "Fubsy",
  "Adorbs",
  "Dollop",
  "Fipple",
  "Aloof",
  "Foppish",
  "Yitten",
  "Pronk",
];

export const generateRandomName = () => {
  const firstName =
    availableNames[Math.floor(Math.random() * availableNames.length)];
  const lastName =
    availableNames[Math.floor(Math.random() * availableNames.length)];

  return `${firstName} ${lastName}`;
};
