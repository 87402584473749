import { Typography } from "@mui/material";
import * as React from "react";
import { useState } from "react";
import { loginRequest } from "../api/sessions";
import { Colors } from "../helpers/colors";

const Signin = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const handleSubmit = async (event: React.SyntheticEvent) => {
    try {
      event.preventDefault();
      await loginRequest({ email, password });
      window.location.replace("/");
    } catch (err: any) {
      console.log("HEY AN ERRO");
      setErrorMessage(err.response.data.message);
    }
  };

  return (
    <div style={styles.wrapper}>
      {!!errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
      <form style={styles.form} onSubmit={handleSubmit}>
        <Typography variant="h2">flicklist.lol</Typography>
        <input
          style={styles.textInput}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          placeholder="email"
          type="email"
        />
        <input
          style={styles.textInput}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          placeholder="password"
          type="password"
        />
        <input style={styles.submitButton} type="submit" value="login" />
        <div style={styles.signupLink}>
          don't have an account? <a href="/signup">create one</a>
        </div>
      </form>
    </div>
  );
};

const styles = {
  wrapper: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: 300,
  } as React.CSSProperties,
  textInput: {
    height: 40,
    width: "100%",
    padding: 10,
    borderRadius: 8,
    fontSize: 20,
    marginBottom: 10,
    backgroundColor: Colors.eggShell,
    borderWidth: "thin",
    borderColor: Colors.darkGreen,
    webkitAppearance: "none",
  },
  submitButton: {
    width: 320,
    height: 50,
    borderRadius: 8,
    fontSize: 20,
    backgroundColor: Colors.orange,
    borderWidth: 0,
    color: Colors.eggShell,
    marginBottom: 10,
  },
  signupLink: {
    fontSize: 18,
    textDecoration: "none",
    fontFamily: '"Noto Sans", sans-serif',
    color: Colors.kindaBlack,
  },
};

export default Signin;
