import { FilmDialog } from "./FilmDialog";
import * as React from "react";
import { useState } from "react";
import { Film, FilmList } from "../modules/films/types";
import { Size, useWindowSize } from "../hooks/useWindowSize";
import { useIsMobile } from "../hooks/useIsMobile";
import { Colors } from "../helpers/colors";
import { splitFilmsIntoRows } from "../helpers/films";
import { Edit } from "@mui/icons-material";
import { Typography, IconButton } from "@mui/material";
import { createFilmList } from "../api/filmLists";
import { generateRandomName } from "../helpers/randomNameGenerator";
import { useNavigate } from "react-router-dom";
import CheckCircle from "@mui/icons-material/CheckCircle";

type Props = {
  films: Film[];
  filmListId?: FilmList["id"];
  leftAlignedWithTwoRows?: boolean;
  noFilmLists?: boolean;
};

const DESKTOP_IMAGE_WIDTH = 193;
const MOBILE_IMAGE_WIDTH = 100;
const IMAGE_BORDER_SIZE = 4;
const DESKTOP_IMAGE_CARD_MARGIN_RIGHT = 10;

const MOBILE_IMAGE_CARD_MARGIN_RIGHT = 4;

const DESKTOP_EMPTY_CARD_HEIGHT = 289;
const DESKTOP_EMPTY_CARD_WIDTH = 199;
const MOBILE_EMPTY_CARD_HEIGHT = 150;
const MOBILE_EMPTY_CARD_WIDTH = 100;

const ScrollableFilmList = ({
  films,
  filmListId,
  leftAlignedWithTwoRows,
  noFilmLists,
}: Props) => {
  const isMobile = useIsMobile();
  const [stagedFilm, setStagedFilm] = useState<Film | undefined>(undefined);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const size: Size = useWindowSize();

  const imageCardMarginRight = isMobile
    ? MOBILE_IMAGE_CARD_MARGIN_RIGHT
    : DESKTOP_IMAGE_CARD_MARGIN_RIGHT;
  const imageWidth = isMobile ? MOBILE_IMAGE_WIDTH : DESKTOP_IMAGE_WIDTH;
  const fullImageWidth =
    imageWidth + IMAGE_BORDER_SIZE + DESKTOP_IMAGE_CARD_MARGIN_RIGHT;

  const openDialog = (film: Film) => {
    setStagedFilm(film);
    setIsModalOpen(true);
  };

  const handleOnWatchedPress = (watched: boolean, filmId: Film["id"]) => {
    const changedFilm = films.find((film) => film.id === filmId);

    if (!!changedFilm) {
      changedFilm.watched = watched;
    }
  };

  return (
    <>
      {!!leftAlignedWithTwoRows ? (
        <ListWithLeftAlignedFilms
          films={films}
          isMobile={isMobile}
          size={size}
          fullImageWidth={fullImageWidth}
          filmOnClick={openDialog}
          imageCardMarginRight={imageCardMarginRight}
        />
      ) : (
        <ListWithCenterAlignedFilms
          films={films}
          isMobile={isMobile}
          size={size}
          fullImageWidth={fullImageWidth}
          filmOnClick={openDialog}
          imageCardMarginRight={imageCardMarginRight}
          noFilmLists={!!noFilmLists}
        />
      )}

      {!!filmListId && !!stagedFilm && (
        <FilmDialog
          film={stagedFilm}
          filmListId={filmListId}
          onClose={() => setIsModalOpen(false)}
          open={isModalOpen}
          onWatchedPress={handleOnWatchedPress}
        />
      )}
    </>
  );
};

type AlignedFilmListProps = {
  films: Film[];
  isMobile: boolean;
  size: Size;
  fullImageWidth: number;
  imageCardMarginRight: number;
  noFilmLists?: boolean;
  filmOnClick: (film: Film) => void;
};

const ListWithCenterAlignedFilms = ({
  films,
  isMobile,
  size,
  fullImageWidth,
  imageCardMarginRight,
  noFilmLists,
  filmOnClick,
}: AlignedFilmListProps) => {
  const navigate = useNavigate();

  const calculateEmptyCards = (filmsLength: number) => {
    if (!size.width) {
      return 0;
    }

    const allFilmsWidth = filmsLength * fullImageWidth;
    const spaceToFill = size.width - allFilmsWidth;
    if (spaceToFill < fullImageWidth) {
      return 0;
    } // don't fill if there's less than one card's space to fill

    const emptyCards = spaceToFill / fullImageWidth;

    return 2 * Math.round(emptyCards / 2) + 2; // round cards to nearest even number and add 2 just so we can cover all the space
  };

  const handleCreateList = async () => {
    try {
      const response = await createFilmList(generateRandomName());
      const filmList = response.data.film_list;
      navigate(`/film_lists/${filmList.id}/edit`, { state: filmList });
    } catch (err) {
      console.log("hey error creating film list");
    }
  };

  const numberOfEmptyCardsOnOneSide = (filmsLength: number) =>
    !!calculateEmptyCards(filmsLength)
      ? calculateEmptyCards(filmsLength) / 2
      : 0;

  return (
    <div style={styles.normalContainer(!!calculateEmptyCards(films.length))}>
      {!!numberOfEmptyCardsOnOneSide(films.length) && (
        <EmptyCards
          numberOfCards={numberOfEmptyCardsOnOneSide(films.length)}
          isMobile={isMobile}
          imageCardMarginRight={imageCardMarginRight}
        />
      )}
      {!!noFilmLists && (
        <div style={styles.noListsTitle}>
          <Typography
            sx={Object.assign(
              {},
              styles.listName,
              isMobile && styles.listNameMobile
            )}
            variant="h5"
          >
            {"start my list"}
          </Typography>
          {
            // @ts-ignore
            <IconButton style={styles.editIcon} onClick={handleCreateList}>
              <Edit />
            </IconButton>
          }
        </div>
      )}
      {films.map((film: Film, index: number) => {
        return (
          <React.Fragment key={index}>
            <FilmCard
              film={film}
              onClick={filmOnClick}
              isMobile={isMobile}
              imageCardMarginRight={imageCardMarginRight}
            />
          </React.Fragment>
        );
      })}
      {!!numberOfEmptyCardsOnOneSide(films.length) && (
        <EmptyCards
          numberOfCards={numberOfEmptyCardsOnOneSide(films.length)}
          isMobile={isMobile}
          imageCardMarginRight={imageCardMarginRight}
        />
      )}
    </div>
  );
};

const ListWithLeftAlignedFilms = ({
  films,
  isMobile,
  size,
  fullImageWidth,
  imageCardMarginRight,
  filmOnClick,
}: AlignedFilmListProps) => {
  const calculateEmptyCards = (filmsLength: number) => {
    if (!size.width) {
      return 0;
    }
    const allFilmsWidth = filmsLength * fullImageWidth;
    const spaceToFill = size.width - allFilmsWidth;
    if (spaceToFill < fullImageWidth) {
      return 0;
    } // don't fill if there's less than one card's space to fill

    const emptyCards = spaceToFill / fullImageWidth;
    return Math.round(emptyCards) + 1; // round cards to nearest whole number and add 1 just so we can cover all the space
  };

  const filmsOnEachRow = splitFilmsIntoRows(isMobile, films);

  const numberOfEmptyCardsFollowing = (filmsLength: number) =>
    !!calculateEmptyCards(filmsLength) ? calculateEmptyCards(filmsLength) : 0;

  let filmNumber = 0;

  return (
    <>
      {Object.keys(filmsOnEachRow).map((key: String, index: number) => {
        const row = key as keyof typeof filmsOnEachRow;
        return (
          <div
            style={styles.leftAlignedFilmsContainer(
              isMobile,
              !!numberOfEmptyCardsFollowing(filmsOnEachRow[row]!.length)
            )}
            key={index}
          >
            {filmsOnEachRow[row]!.map((film: Film, index: number) => {
              if (!film.watched) {
                filmNumber++;
              }

              return (
                <React.Fragment key={index}>
                  <FilmCard
                    film={film}
                    onClick={filmOnClick}
                    isMobile={isMobile}
                    imageCardMarginRight={imageCardMarginRight}
                    filmNumber={!film.watched && filmNumber}
                  />
                </React.Fragment>
              );
            })}
            {!!numberOfEmptyCardsFollowing(filmsOnEachRow[row]!.length) && (
              <EmptyCards
                numberOfCards={numberOfEmptyCardsFollowing(
                  filmsOnEachRow[row]!.length
                )}
                isMobile={isMobile}
                imageCardMarginRight={imageCardMarginRight}
              />
            )}
          </div>
        );
      })}
    </>
  );
};

type FilmCardProps = {
  film: Film;
  onClick: (film: Film) => void;
  isMobile: boolean;
  imageCardMarginRight: number;
  filmNumber?: number;
};

const FilmCard = ({
  film,
  onClick,
  isMobile,
  imageCardMarginRight,
  filmNumber,
}: FilmCardProps) => (
  <div style={styles.card(imageCardMarginRight)} onClick={() => onClick(film)}>
    <div style={styles.filmNumber}>{filmNumber}</div>
    <img
      key={film.id}
      style={Object.assign(
        {},
        styles.image(isMobile),
        isMobile && styles.imageMobile
      )}
      src={film.image_url}
      alt={film.title}
    />
    {!!film.watched && (
      <CheckCircle
        sx={{
          position: "absolute",
          bottom: 14,
          right: 10,
          color: Colors.orange,
        }}
      />
    )}
  </div>
);

type EmptyCardProps = {
  numberOfCards: number;
  isMobile: boolean;
  imageCardMarginRight: number;
};

const EmptyCards = ({
  numberOfCards,
  isMobile,
  imageCardMarginRight,
}: EmptyCardProps) => {
  const arrayWithLength = Array.from(Array(numberOfCards));
  return (
    <>
      {arrayWithLength.map((el, index) => {
        return (
          <div
            key={index}
            style={Object.assign(
              {},
              styles.emptyCard(imageCardMarginRight, isMobile),
              isMobile && styles.emptyCardsMobile
            )}
          />
        );
      })}
    </>
  );
};
const styles = {
  filmNumber: {
    position: 'absolute',
    left: 10,
    top: 10,
    width: 30,
    lineHeight: '30px',
    marginRight: 5,
    textAlign: 'center',
    borderRadius: '50%',
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    color: Colors.kindaBlack,
    fontWeight: 'bold',
    fontFamily: '"Noto Sans", sans-serif',
  },
  wrapper: (leftAlignedWithTwoRows: boolean) =>
    ({
      display: "flex",
      flexWrap: leftAlignedWithTwoRows ? "wrap" : "nowrap",
      overflowX: leftAlignedWithTwoRows ? "visible" : "auto",
      overflowScrolling: "touch",
      WebkitOverflowScrolling: "touch",
      marginBottom: 40,
    } as React.CSSProperties),
  normalContainer: (hasEmptyCards: boolean) =>
    ({
      position: "relative",
      display: "flex",
      justifyContent: hasEmptyCards ? "center" : "flex-start",
      flexWrap: "nowrap",
      overflowX: hasEmptyCards ? "hidden" : "auto",
      overflowScrolling: "touch",
      WebkitOverflowScrolling: "touch",
      marginBottom: 40,
    } as React.CSSProperties),
  leftAlignedFilmsContainer: (isMobile: boolean, hasEmptyCards: boolean) =>
    ({
      display: "flex",
      overflowScrolling: "touch",
      WebkitOverflowScrolling: "touch",
      marginBottom: isMobile ? 2 : 10,
      overflowX: hasEmptyCards ? "hidden" : "visible",
    } as React.CSSProperties),
  emptyCard: (marginRight: number, isMobile: boolean) =>
    ({
      flex: "0 0 auto",
      marginRight,
      position: "relative",
      height: DESKTOP_EMPTY_CARD_HEIGHT,
      width: DESKTOP_EMPTY_CARD_WIDTH,
      borderRadius: isMobile ? 15 : 25,
      border: `${IMAGE_BORDER_SIZE}px solid white`,
      backgroundColor: Colors.white,
      opacity: "0.5",
    } as React.CSSProperties),
  emptyCardsMobile: {
    height: MOBILE_EMPTY_CARD_HEIGHT,
    width: MOBILE_EMPTY_CARD_WIDTH,
  },
  card: (marginRight: number) =>
    ({
      flex: "0 0 auto",
      marginRight,
      position: "relative",
    } as React.CSSProperties),
  image: (isMobile: boolean) => ({
    width: DESKTOP_IMAGE_WIDTH,
    borderRadius: isMobile ? 15 : 25,
    border: `${IMAGE_BORDER_SIZE}px solid #f8f5f2`,
  }),
  imageMobile: {
    width: MOBILE_IMAGE_WIDTH,
  },
  listName: {
    textTransform: "lowercase",
    fontFamily: '"Noto Sans", sans-serif',
    fontWeight: 300,
    color: Colors.kindaBlack,
    fontSize: 39,
  } as React.CSSProperties,
  listNameMobile: {
    fontSize: 28,
  } as React.CSSProperties,
  noListsTitle: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    height: "100%",
    zIndex: 1,
  } as React.CSSProperties,
  editIcon: {
    color: Colors.orange,
    width: 50,
    position: "absolute",
    left: "100%",
  } as React.CSSProperties,
};

export default ScrollableFilmList;
